export const ErrorCode = {
  LegacyNotFound: 'notFound',
  LegacyActivationPending: 'activationPending',
  LegacyAuthorizationPending: 'authorizationPending',
  LegacyExpiredToken: 'expiredToken',
  LegacySlowDown: 'slowDown',
  LegacyConflict: 'conflict',
  InvalidArgument: 'INVALID_ARGUMENT',
  Internal: 'INTERNAL',
  NotEmpty: 'NOT_EMPTY',
  AlreadyExists: 'ALREADY_EXISTS',
  NotFound: 'NOT_FOUND',
  PermissionDenied: 'PERMISSION_DENIED',
} as const;

/** Represents a known backend error code */
export type ErrorCode = (typeof ErrorCode)[keyof typeof ErrorCode];
