export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * Represent a date-time string at UTC in RFC 3339 format.
   *
   * Where an RFC 3339 compliant date-time string has a time-zone other than UTC, it
   * is shifted to UTC.
   */
  DateTime: { input: string; output: string; }
  /** Represent duration in seconds. */
  Duration: { input: number; output: number; }
  /** Represent time of day as offset in seconds since midnight. */
  TimeOfDay: { input: number; output: number; }
};

export type ActivateOverviewCodePayload = {
  __typename?: 'ActivateOverviewCodePayload';
  expiresIn: Scalars['Duration']['output'];
  userCode: Scalars['String']['output'];
};

export type AddBookingInput = {
  checkedIn?: InputMaybe<Scalars['DateTime']['input']>;
  deskId: Scalars['ID']['input'];
  endTime: Scalars['DateTime']['input'];
  focusMode?: InputMaybe<Scalars['Boolean']['input']>;
  idTag?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
  userEmail?: InputMaybe<Scalars['String']['input']>;
  /** If userID and userEmail or idTag is not set, an anonymous booking will be created */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddBuildingInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  openHours?: InputMaybe<WeeklyOpenHoursInput>;
  overrideOpenHours?: InputMaybe<Scalars['Boolean']['input']>;
  timePresentation?: InputMaybe<TimePresentation>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type AddCalendarSourceInput = {
  settings: SettingsInput;
};

export type AddChannelInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type AddDeskInput = {
  floorId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  presenceDetection?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddDeviceCommandInput = {
  command: Scalars['String']['input'];
  service: Scalars['String']['input'];
};

export type AddDeviceTypeInput = {
  attributes?: InputMaybe<DeviceTypeAttributesInput>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type AddFirmwareInput = {
  channels?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceTypeId: Scalars['ID']['input'];
  publicKey?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
  variant?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['String']['input'];
};

export type AddFloorInput = {
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type AddInvitationInput = {
  email: Scalars['String']['input'];
  role: Role;
};

export type AddLicenseInput = {
  count: Scalars['Int']['input'];
  duration: Scalars['Int']['input'];
  licenseTypeId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddLicensesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type AddOrganizationMessageInput = {
  canBeClosed: Scalars['Boolean']['input'];
  content: Scalars['String']['input'];
  hideAt?: InputMaybe<Scalars['DateTime']['input']>;
  organizations?: InputMaybe<Array<Scalars['ID']['input']>>;
  showAt?: InputMaybe<Scalars['DateTime']['input']>;
  showToAllOrganizations: Scalars['Boolean']['input'];
  type: OrganizationMessageType;
};

export type AddOverviewInput = {
  columnCount: Scalars['Int']['input'];
  floorId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  showDateTime: Scalars['Boolean']['input'];
  showLogo: Scalars['Boolean']['input'];
  tiles?: InputMaybe<Array<InputMaybe<AddTileInput>>>;
  type: OverviewType;
};

export type AddPlaceInput = {
  attributes?: InputMaybe<PlaceAttributesInput>;
  name: Scalars['String']['input'];
  parentPlaceId?: InputMaybe<Scalars['ID']['input']>;
  placeTypeId: Scalars['ID']['input'];
  settings?: InputMaybe<PlaceSettingsInput>;
};

export type AddPlaceTypeInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type AddResourceTypeInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type AddRoomBookingInput = {
  attendees?: InputMaybe<Array<AttendeeInput>>;
  /** `checkedIn` is not supported when creating space connect bookings. */
  checkedIn?: InputMaybe<Scalars['DateTime']['input']>;
  endTime: Scalars['DateTime']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  roomId: Scalars['ID']['input'];
  startTime: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  /** If userId is omitted or `null` the booking is an anonymous booking. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddRoomInput = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  floorId: Scalars['ID']['input'];
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  resources?: InputMaybe<Array<RoomResource>>;
};

export type AddTileInput = {
  directionIndicator?: InputMaybe<Direction>;
  floorId?: InputMaybe<Scalars['ID']['input']>;
  hideBookingInformation?: InputMaybe<Scalars['Boolean']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  type: TileType;
};

export type AssignFirmwareFilter = {
  channels?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  devices?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizations?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Attendee = {
  __typename?: 'Attendee';
  email: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organizer?: Maybe<Scalars['Boolean']['output']>;
  responseStatus: AttendeeResponseStatus;
  type: AttendeeType;
};

export type AttendeeInput = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AttendeeType>;
};

export const AttendeeResponseStatus = {
  Accepted: 'ACCEPTED',
  Declined: 'DECLINED',
  NeedsAction: 'NEEDS_ACTION',
  Tentative: 'TENTATIVE',
  Unknown: 'UNKNOWN'
} as const;

export type AttendeeResponseStatus = typeof AttendeeResponseStatus[keyof typeof AttendeeResponseStatus];
export const AttendeeType = {
  Optional: 'OPTIONAL',
  Required: 'REQUIRED',
  Resource: 'RESOURCE',
  Unknown: 'UNKNOWN'
} as const;

export type AttendeeType = typeof AttendeeType[keyof typeof AttendeeType];
export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  accessToken: Scalars['String']['output'];
  /** Duration until the accessToken expires */
  expiresIn: Scalars['Duration']['output'];
  subject: Subject;
};

export type Availability = {
  __typename?: 'Availability';
  from: Scalars['DateTime']['output'];
  until: Scalars['DateTime']['output'];
};

export type AvailableFilter = {
  closeMatches?: InputMaybe<Scalars['Boolean']['input']>;
  from: Scalars['DateTime']['input'];
  until: Scalars['DateTime']['input'];
};

export type Booking = {
  __typename?: 'Booking';
  /** @deprecated moved to attributes.desk.arrivedAt */
  arrivedAt?: Maybe<Scalars['DateTime']['output']>;
  attributes: BookingAttributes;
  canceled?: Maybe<Scalars['DateTime']['output']>;
  checkedIn?: Maybe<Scalars['DateTime']['output']>;
  checkedOut?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated a booking can now be for any resource type, use resource instead */
  desk?: Maybe<Desk>;
  endTime: Scalars['DateTime']['output'];
  /** @deprecated moved to attributes.desk.focusMode */
  focusMode: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isWholeDay?: Maybe<Scalars['Boolean']['output']>;
  resource?: Maybe<Resource>;
  /** @deprecated moved to attributes.desk.skipAwayFromDeskReminder */
  skipAwayFromDeskReminder: Scalars['Boolean']['output'];
  /** @deprecated moved to attributes.desk.skipLateArrivalReminder */
  skipLateArrivalReminder: Scalars['Boolean']['output'];
  startTime: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userDeleted: Scalars['Boolean']['output'];
};

export type BookingAttributes = DeskBookingAttributes | RoomBookingAttributes;

export type BookingEdge = {
  __typename?: 'BookingEdge';
  node: Booking;
};

export type BookingEvent = {
  __typename?: 'BookingEvent';
  added?: Maybe<Booking>;
  deleted?: Maybe<Booking>;
  updated?: Maybe<Booking>;
};

export type BookingFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  deskId?: InputMaybe<Scalars['ID']['input']>;
  iCalUID?: InputMaybe<BookingFilterICalUid>;
  /** onlyActiveBookings filters out canceled and checked out bookings (and bookings where the user has been deleted) */
  onlyActiveBookings?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  resourceTypeId?: InputMaybe<BookingResourceTypeId>;
  timeWindow?: InputMaybe<TimeWindow>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type BookingFilterICalUid = {
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type BookingOrder = {
  desc?: Scalars['Boolean']['input'];
  field: BookingOrderField;
};

export const BookingOrderField = {
  CreatedAt: 'CREATED_AT',
  DeskName: 'DESK_NAME',
  EndTime: 'END_TIME',
  OrganizationName: 'ORGANIZATION_NAME',
  StartTime: 'START_TIME',
  UpdatedAt: 'UPDATED_AT',
  UserName: 'USER_NAME'
} as const;

export type BookingOrderField = typeof BookingOrderField[keyof typeof BookingOrderField];
export const BookingResourceTypeId = {
  Desk: 'DESK',
  Room: 'ROOM'
} as const;

export type BookingResourceTypeId = typeof BookingResourceTypeId[keyof typeof BookingResourceTypeId];
export type BookingsConnection = {
  __typename?: 'BookingsConnection';
  bookings: Array<Booking>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Building = {
  __typename?: 'Building';
  address?: Maybe<Scalars['String']['output']>;
  brokenEquipmentReportEmails?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  deviceScreenTemplate?: Maybe<DeviceScreenTemplate>;
  floors: Array<Floor>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  openHours: WeeklyOpenHours;
  overrideOpenHours: Scalars['Boolean']['output'];
  timePresentation?: Maybe<TimePresentation>;
  timezone?: Maybe<Scalars['String']['output']>;
  /** Number of resources not assigned to a plan for this or any ascending place. */
  unassignedPlanResources: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BuildingAttributes = {
  __typename?: 'BuildingAttributes';
  address?: Maybe<Scalars['String']['output']>;
  unassignedPlanResources?: Maybe<Scalars['Int']['output']>;
};

export type BuildingAttributesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  unassignedPlanResources?: InputMaybe<Scalars['Int']['input']>;
};

export type BuildingFilter = {
  hasUnassignedPlanResources?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if `name` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type BuildingOrder = {
  desc?: Scalars['Boolean']['input'];
  field: BuildingOrderField;
};

export const BuildingOrderField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type BuildingOrderField = typeof BuildingOrderField[keyof typeof BuildingOrderField];
export type BuildingSettings = {
  __typename?: 'BuildingSettings';
  brokenEquipmentReportEmails?: Maybe<Array<Scalars['String']['output']>>;
  deviceScreenTemplate?: Maybe<DeviceScreenTemplate>;
  language?: Maybe<Scalars['String']['output']>;
  openHours?: Maybe<WeeklyOpenHours>;
  overrideOpenHours?: Maybe<Scalars['Boolean']['output']>;
  timePresentation?: Maybe<TimePresentation>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type BuildingSettingsInput = {
  brokenEquipmentReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceScreenTemplate?: InputMaybe<DeviceScreenTemplate>;
  language?: InputMaybe<Scalars['String']['input']>;
  openHours?: InputMaybe<WeeklyOpenHoursInput>;
  overrideOpenHours?: InputMaybe<Scalars['Boolean']['input']>;
  timePresentation?: InputMaybe<TimePresentation>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type BuildingsConnection = {
  __typename?: 'BuildingsConnection';
  buildings: Array<Building>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Calendar */
export type Calendar = {
  __typename?: 'Calendar';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isResource: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  membershipId: Scalars['ID']['output'];
  /** @deprecated always empty, will be removed */
  remoteData: Scalars['String']['output'];
  remoteId: Scalars['ID']['output'];
  remoteSubExpiresAt: Scalars['DateTime']['output'];
  remoteSubId: Scalars['String']['output'];
  resourceID: Scalars['ID']['output'];
  sourceId: Scalars['ID']['output'];
  syncToken: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  attendees: Array<Attendee>;
  calendarId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creator: Scalars['String']['output'];
  endTime: Scalars['DateTime']['output'];
  iCalUID: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isRecurring: Scalars['Boolean']['output'];
  isWholeDay: Scalars['Boolean']['output'];
  meetingProvider: MeetingProvider;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated always empty, will be removed */
  remoteData: Scalars['String']['output'];
  remoteId: Scalars['ID']['output'];
  sensitivity: CalendarEventSensitivity;
  startTime: Scalars['DateTime']['output'];
  timesSynced: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CalendarEventOrder = {
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  field: CalendarEventOrderField;
};

export const CalendarEventOrderField = {
  StartTime: 'START_TIME'
} as const;

export type CalendarEventOrderField = typeof CalendarEventOrderField[keyof typeof CalendarEventOrderField];
/** Calendar Event */
export const CalendarEventSensitivity = {
  Confidential: 'CONFIDENTIAL',
  Default: 'DEFAULT',
  Private: 'PRIVATE',
  Public: 'PUBLIC'
} as const;

export type CalendarEventSensitivity = typeof CalendarEventSensitivity[keyof typeof CalendarEventSensitivity];
export type CalendarEventsConnection = {
  __typename?: 'CalendarEventsConnection';
  events: Array<CalendarEvent>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type CalendarEventsFilter = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Calendar Source */
export const CalendarProvider = {
  Google: 'GOOGLE',
  Microsoft: 'MICROSOFT'
} as const;

export type CalendarProvider = typeof CalendarProvider[keyof typeof CalendarProvider];
export const CalendarRegistrationStatus = {
  Registered: 'REGISTERED',
  Unregistered: 'UNREGISTERED'
} as const;

export type CalendarRegistrationStatus = typeof CalendarRegistrationStatus[keyof typeof CalendarRegistrationStatus];
export type CalendarSettings = {
  __typename?: 'CalendarSettings';
  defaultAddMeetingLinksToEvents: Scalars['Boolean']['output'];
};

export type CalendarSettingsInput = {
  defaultAddMeetingLinkstoEvents?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CalendarSource = {
  __typename?: 'CalendarSource';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['String']['output'];
  provider: CalendarProvider;
  resourceAdmin: Scalars['String']['output'];
  settings: Scalars['String']['output'];
  syncErrors?: Maybe<Array<Scalars['String']['output']>>;
  syncedAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CalendarSourcesConnection = {
  __typename?: 'CalendarSourcesConnection';
  nextPageToken?: Maybe<Scalars['String']['output']>;
  sources: Array<CalendarSource>;
};

export const CalendarType = {
  Resource: 'RESOURCE',
  User: 'USER'
} as const;

export type CalendarType = typeof CalendarType[keyof typeof CalendarType];
export type CalendarsConnection = {
  __typename?: 'CalendarsConnection';
  calendars: Array<Calendar>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type CalendarsFilter = {
  registrationStatus?: InputMaybe<CalendarRegistrationStatus>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CalendarType>;
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ChannelOrder = {
  desc?: Scalars['Boolean']['input'];
  field: ChannelOrderField;
};

export const ChannelOrderField = {
  Name: 'NAME'
} as const;

export type ChannelOrderField = typeof ChannelOrderField[keyof typeof ChannelOrderField];
export type ChannelsConnection = {
  __typename?: 'ChannelsConnection';
  channels: Array<Channel>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Colleague = {
  __typename?: 'Colleague';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  membership?: Maybe<Membership>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
};

export type ColleagueFilter = {
  /** Checks if `Colleague.name` or `Colleague.email` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ColleaguesConnection = {
  __typename?: 'ColleaguesConnection';
  colleagues: Array<Colleague>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type CreateCalendarEventInput = {
  attendees: Array<AttendeeInput>;
  endTime: Scalars['DateTime']['input'];
  meetingProvider?: InputMaybe<MeetingProvider>;
  name: Scalars['String']['input'];
  sensitivity?: InputMaybe<CalendarEventSensitivity>;
  startTime: Scalars['DateTime']['input'];
};

export type CreateDevicePayload = {
  __typename?: 'CreateDevicePayload';
  device: Device;
  legacyToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type CreateOrganizationInput = {
  domain: Scalars['String']['input'];
  /** @deprecated legacy, will be removed */
  eula?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** @deprecated legacy, will be removed */
  ola?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePlanInput = {
  annotations: Scalars['String']['input'];
  mediaId: Scalars['ID']['input'];
  placeId: Scalars['ID']['input'];
};

export type DeleteCalendarEventResponse = {
  __typename?: 'DeleteCalendarEventResponse';
  id: Scalars['String']['output'];
};

export type DeleteCalendarSourceResponse = {
  __typename?: 'DeleteCalendarSourceResponse';
  deletedCalendarsCount: Scalars['Int']['output'];
  deletedEventsCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
};

export type Desk = {
  __typename?: 'Desk';
  /** Contains the available timespan for this desk within the requested period. */
  available?: Maybe<Availability>;
  bookingCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The currently active booking, if any */
  currentBooking?: Maybe<Booking>;
  floor?: Maybe<Floor>;
  id: Scalars['ID']['output'];
  license?: Maybe<License>;
  name: Scalars['String']['output'];
  /** The next upcoming booking, if any */
  nextBooking?: Maybe<Booking>;
  orgId: Scalars['ID']['output'];
  /** If true, the presence detection sensor will be enabled on the device */
  presenceDetection: Scalars['Boolean']['output'];
  settings: DeskBookingSettings;
  /**
   * Time presentation used for this desk. The value is picked from the building
   * with the organization default as fallback.
   */
  timePresentation: TimePresentation;
  /**
   * Time zone of this desk. The time zone is picked from the building with
   * the organization default as fallback.
   */
  timeZone: Scalars['String']['output'];
  /** Time offset from UTC in seconds */
  timeZoneOffset: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DeskAttributes = {
  __typename?: 'DeskAttributes';
  deskType?: Maybe<Scalars['String']['output']>;
  presenceDetection?: Maybe<Scalars['Boolean']['output']>;
};

export type DeskBookingAttributes = {
  __typename?: 'DeskBookingAttributes';
  arrivedAt?: Maybe<Scalars['DateTime']['output']>;
  focusMode: Scalars['Boolean']['output'];
  skipAwayFromDeskReminder: Scalars['Boolean']['output'];
  skipLateArrivalReminder: Scalars['Boolean']['output'];
};

export type DeskBookingSettings = {
  __typename?: 'DeskBookingSettings';
  /**
   * If the user has checked in, but has been absent from the desk for this duration,
   * a notice is pushed to the user.
   */
  absenceReminderDelay: Scalars['Duration']['output'];
  /** If true, the desk device will automatically check-in a current booking on presence detection */
  autoCheckInOnPresence: Scalars['Boolean']['output'];
  /** How many calendar days in advance you may place a booking */
  daysInAdvanceLimit: Scalars['Int']['output'];
  /** Offset for `daysInAdvanceLimit`. */
  daysInAdvanceLimitOffset: Scalars['TimeOfDay']['output'];
  /** The max booking duration limit */
  durationLimit: Scalars['Duration']['output'];
  /** Period of time, before start of booking, check-in is allowed. */
  earlyCheckIn: Scalars['Duration']['output'];
  /** The initial/default booking duration */
  initialDuration: Scalars['Duration']['output'];
  /** If the user has not showed up after this delay, the booking is canceled. */
  lateArrivalCancelDelay: Scalars['Duration']['output'];
  /** If the user has not showed up after this delay, a late-arrival notice is pushed to the user */
  lateArrivalReminderDelay: Scalars['Duration']['output'];
  /** If true, the booker of a booking is private for all bookings. */
  privateOnly: Scalars['Boolean']['output'];
  /** If true, user are allowed to check-in using qr code in the app. */
  qrCodeCheckIn: Scalars['Boolean']['output'];
  /** If true, users are allowed to check-in remotely using the app */
  remoteCheckIn: Scalars['Boolean']['output'];
  /**
   * If true, the desk device will require identification with RFID tag to allow
   * the user to book the desk.
   */
  requireIdentForBooking: Scalars['Boolean']['output'];
  /** If true, the user must identify with RFID tag to check in on the desk device */
  requireIdentForCheckIn: Scalars['Boolean']['output'];
  /** If true, the user must identify with RFID tag to extend or check-out a booking */
  requireIdentForUpdating: Scalars['Boolean']['output'];
};

export type DeskBookingSettingsInput = {
  absenceReminderDelay?: InputMaybe<Scalars['Duration']['input']>;
  autoCheckInOnPresence?: InputMaybe<Scalars['Boolean']['input']>;
  daysInAdvanceLimit?: InputMaybe<Scalars['Int']['input']>;
  daysInAdvanceLimitOffset?: InputMaybe<Scalars['TimeOfDay']['input']>;
  durationLimit?: InputMaybe<Scalars['Duration']['input']>;
  earlyCheckIn?: InputMaybe<Scalars['Duration']['input']>;
  initialDuration?: InputMaybe<Scalars['Duration']['input']>;
  lateArrivalCancelDelay?: InputMaybe<Scalars['Duration']['input']>;
  lateArrivalReminderDelay?: InputMaybe<Scalars['Duration']['input']>;
  qrCodeCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  remoteCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForBooking?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForUpdating?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeskFilter = {
  available?: InputMaybe<AvailableFilter>;
  bookingCountTimeWindow?: InputMaybe<TimeWindow>;
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  excludeDesksAssignedToFloor?: InputMaybe<Scalars['Boolean']['input']>;
  floors?: InputMaybe<Array<Scalars['ID']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type DeskOrder = {
  desc?: Scalars['Boolean']['input'];
  field: DeskOrderField;
};

export const DeskOrderField = {
  BookingCount: 'BOOKING_COUNT',
  CreatedAt: 'CREATED_AT',
  LocationName: 'LOCATION_NAME',
  Name: 'NAME',
  PresenceDetection: 'PRESENCE_DETECTION',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type DeskOrderField = typeof DeskOrderField[keyof typeof DeskOrderField];
export type DeskStats = {
  __typename?: 'DeskStats';
  booked: Scalars['Int']['output'];
  occupied: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DesksConnection = {
  __typename?: 'DesksConnection';
  desks: Array<Desk>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Device = {
  __typename?: 'Device';
  /**
   * Weekly schedule where the device is considered to be "active", i.e. the office
   * is open and there is likely people using the device.
   */
  activeHours: WeeklyOpenHours;
  /**
   * Contains meta data about the firmware assigned to this device.
   * This should *not* be used by the device to know which firmware it
   * should upgrade to. Instead, devices should use the `nextFirmware` field.
   */
  assignedFirmware?: Maybe<Firmware>;
  attributes?: Maybe<DeviceAttributes>;
  /** The assigned firmware channel */
  channel?: Maybe<Channel>;
  createdAt: Scalars['DateTime']['output'];
  /** The assigned desk */
  desk?: Maybe<Desk>;
  /** The assigned desk ID */
  deskId?: Maybe<Scalars['ID']['output']>;
  /** The firmware public key last reported by the device via `nextFirmware` */
  firmwarePublicKey?: Maybe<Scalars['String']['output']>;
  firmwareVariant?: Maybe<Scalars['String']['output']>;
  /** Identifies a unique device in backend. Not related to the hardware ID. */
  id: Scalars['ID']['output'];
  /** Language of the device. Falls back to organization default. */
  language: Scalars['String']['output'];
  /**
   * Contains meta data about the latest firmware available to this device.
   * This should *not* be used by the device to know which firmware it
   * should upgrade to. Instead, devices should use the `nextFirmware` field.
   */
  latestFirmware?: Maybe<Firmware>;
  /**
   * Contains the next firmware in the upgrade chain for this device.
   * In the normal case this is the same as assignedFirmware, but when
   * a device must do some intermediate upgrades before reaching the
   * target version, this firmware will show the next firmware it
   * can/should upgrade to depending on the extra information suppplied
   * by the device
   */
  nextFirmware?: Maybe<Firmware>;
  orgId: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
  place?: Maybe<Place>;
  placeId?: Maybe<Scalars['ID']['output']>;
  /** The assigned room */
  room?: Maybe<Room>;
  /** The assigned room ID */
  roomId?: Maybe<Scalars['ID']['output']>;
  /** Evoko serial for the specific device */
  serial?: Maybe<Scalars['String']['output']>;
  state: DeviceState;
  /** Current status as reported by the device */
  status?: Maybe<DeviceStatus>;
  /** Timezone of the device. Falls back to organization default. */
  timezone: Scalars['String']['output'];
  type: DeviceType;
  updatedAt: Scalars['DateTime']['output'];
};


export type DeviceNextFirmwareArgs = {
  fwrev?: InputMaybe<Scalars['String']['input']>;
  hwrev?: InputMaybe<Scalars['String']['input']>;
  pubkey?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceAttributes = {
  __typename?: 'DeviceAttributes';
  architecture: Scalars['String']['output'];
  description: Scalars['String']['output'];
  firmwareVersion: Scalars['String']['output'];
  productFamily: Scalars['String']['output'];
  productModel: Scalars['String']['output'];
  productRevision: Scalars['String']['output'];
  serial: Scalars['String']['output'];
  supportedCommands: Array<DeviceCommand>;
  typeSpecificAttributes?: Maybe<DeviceAttributesUnion>;
};

export type DeviceAttributesUnion = TesiraAttributes;

export type DeviceCommand = {
  __typename?: 'DeviceCommand';
  command: Scalars['String']['output'];
  service: Scalars['String']['output'];
};

export type DeviceCommandInput = {
  arguments: Array<Scalars['String']['input']>;
  command: Scalars['String']['input'];
  deviceId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  service: Scalars['String']['input'];
};

export type DeviceCommandResult = {
  __typename?: 'DeviceCommandResult';
  errorMessage?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DeviceEvent = {
  __typename?: 'DeviceEvent';
  createdAt: Scalars['DateTime']['output'];
  data: DeviceEventData;
  deviceId: Scalars['ID']['output'];
  eventType: DeviceEventType;
  orgId: Scalars['ID']['output'];
};

export type DeviceEventConnection = {
  __typename?: 'DeviceEventConnection';
  deviceEvents: Array<DeviceEvent>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DeviceEventData = DeviceEventStatus | DeviceEventTelemetry;

export type DeviceEventFilter = {
  eventTypes?: InputMaybe<Array<DeviceEventType>>;
  timeWindow?: InputMaybe<TimeWindow>;
};

export type DeviceEventOrder = {
  desc?: Scalars['Boolean']['input'];
  field: DeviceEventOrderField;
};

export const DeviceEventOrderField = {
  CreatedAt: 'CREATED_AT'
} as const;

export type DeviceEventOrderField = typeof DeviceEventOrderField[keyof typeof DeviceEventOrderField];
export type DeviceEventStatus = {
  __typename?: 'DeviceEventStatus';
  description?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  severity: Scalars['Int']['output'];
};

export type DeviceEventStatusInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  severity: Scalars['Int']['input'];
};

export type DeviceEventTelemetry = {
  __typename?: 'DeviceEventTelemetry';
  cpuUtilization?: Maybe<Scalars['Float']['output']>;
  temperature?: Maybe<Scalars['Float']['output']>;
  uptime?: Maybe<Scalars['Int']['output']>;
};

export type DeviceEventTelemetryInput = {
  cpuUtilization?: InputMaybe<Scalars['Float']['input']>;
  firmware?: InputMaybe<Scalars['String']['input']>;
  presence?: InputMaybe<Scalars['Boolean']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  uptime?: InputMaybe<Scalars['Duration']['input']>;
};

export const DeviceEventType = {
  Status: 'STATUS',
  Telemetry: 'TELEMETRY'
} as const;

export type DeviceEventType = typeof DeviceEventType[keyof typeof DeviceEventType];
export type DeviceFilter = {
  channels?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** @deprecated use states instead */
  onlyActivated?: InputMaybe<Scalars['Boolean']['input']>;
  onlyWithErrors?: InputMaybe<Scalars['Boolean']['input']>;
  organizations?: InputMaybe<Array<Scalars['ID']['input']>>;
  places?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Matches search term against `serial` and `desk.name`. */
  search?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<DeviceState>>;
};

export type DeviceInput = {
  /** @deprecated succeeded by `placeId` */
  deskId?: InputMaybe<Scalars['ID']['input']>;
  deviceTypeId?: InputMaybe<Scalars['ID']['input']>;
  placeId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated succeeded by `placeId` */
  roomId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeviceJwt = {
  __typename?: 'DeviceJwt';
  createdAt: Scalars['DateTime']['output'];
  deviceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  jwt: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
};

export type DeviceOrder = {
  desc?: Scalars['Boolean']['input'];
  field: DeviceOrderField;
};

export const DeviceOrderField = {
  AssignedFirmware: 'ASSIGNED_FIRMWARE',
  ChannelName: 'CHANNEL_NAME',
  CreatedAt: 'CREATED_AT',
  DeskName: 'DESK_NAME',
  FirmwarePublicKey: 'FIRMWARE_PUBLIC_KEY',
  FirmwareVariant: 'FIRMWARE_VARIANT',
  OrganizationName: 'ORGANIZATION_NAME',
  RoomName: 'ROOM_NAME',
  Serial: 'SERIAL',
  State: 'STATE',
  TypeName: 'TYPE_NAME',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type DeviceOrderField = typeof DeviceOrderField[keyof typeof DeviceOrderField];
export const DeviceScreenTemplate = {
  LargeBooking: 'LargeBooking',
  LargeResourceName: 'LargeResourceName'
} as const;

export type DeviceScreenTemplate = typeof DeviceScreenTemplate[keyof typeof DeviceScreenTemplate];
export const DeviceState = {
  Missing: 'MISSING',
  Offline: 'OFFLINE',
  Online: 'ONLINE',
  Provisioned: 'PROVISIONED',
  Unprovisioned: 'UNPROVISIONED'
} as const;

export type DeviceState = typeof DeviceState[keyof typeof DeviceState];
export type DeviceStatistics = {
  __typename?: 'DeviceStatistics';
  cpuUtilization?: Maybe<Array<DeviceStatisticsDistribution>>;
  errors?: Maybe<Array<DeviceStatisticsErrorSummary>>;
  missingDevices: Scalars['Int']['output'];
  temperature?: Maybe<Array<DeviceStatisticsDistribution>>;
};

export type DeviceStatisticsDistribution = {
  __typename?: 'DeviceStatisticsDistribution';
  count: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type DeviceStatisticsErrorSummary = {
  __typename?: 'DeviceStatisticsErrorSummary';
  code: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  severity: Scalars['String']['output'];
};

export type DeviceStatisticsFilter = {
  deviceTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeWindow?: InputMaybe<TimeWindow>;
};

export type DeviceStats = {
  __typename?: 'DeviceStats';
  total: Scalars['Int']['output'];
};

export type DeviceStatus = {
  __typename?: 'DeviceStatus';
  cpuUtilization?: Maybe<Scalars['Float']['output']>;
  /** Current firmware version as reported by the device (semver) */
  firmware?: Maybe<Scalars['String']['output']>;
  /** Presence detected by the desk */
  presence?: Maybe<Scalars['Boolean']['output']>;
  /** Current temperature of the device in Celsius or Kelvin (TBD) */
  temperature?: Maybe<Scalars['Float']['output']>;
  /** Timestamp of the last status message received from device */
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  /** Uptime in seconds */
  uptime?: Maybe<Scalars['Int']['output']>;
};

export type DeviceStatusInput = {
  cpuUtilization?: InputMaybe<Scalars['Float']['input']>;
  deviceTime?: InputMaybe<Scalars['DateTime']['input']>;
  firmware?: InputMaybe<Scalars['String']['input']>;
  presence?: InputMaybe<Scalars['Boolean']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  uptime?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  attributes?: Maybe<DeviceTypeAttributes>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DeviceTypeAttributes = {
  __typename?: 'DeviceTypeAttributes';
  commands?: Maybe<Array<DeviceCommand>>;
  /** The interval in seconds between telemetry reports from the device */
  reportInterval: Scalars['Duration']['output'];
};

export type DeviceTypeAttributesInput = {
  commands?: InputMaybe<Array<AddDeviceCommandInput>>;
  /** The interval in seconds between telemetry reports from the device */
  reportInterval: Scalars['Int']['input'];
};

export type DevicesConnection = {
  __typename?: 'DevicesConnection';
  devices: Array<Device>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export const Direction = {
  East: 'EAST',
  North: 'NORTH',
  Northeast: 'NORTHEAST',
  Northwest: 'NORTHWEST',
  South: 'SOUTH',
  Southeast: 'SOUTHEAST',
  Southwest: 'SOUTHWEST',
  West: 'WEST'
} as const;

export type Direction = typeof Direction[keyof typeof Direction];
export type Firmware = {
  __typename?: 'Firmware';
  channels: Array<Channel>;
  createdAt: Scalars['DateTime']['output'];
  deviceType: DeviceType;
  id: Scalars['ID']['output'];
  publicKey?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
  variant?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type FirmwareFilter = {
  channels?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Only returns firmware where `version` or `channels.name` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
  variant?: InputMaybe<Scalars['String']['input']>;
};

export type FirmwareOrder = {
  desc?: Scalars['Boolean']['input'];
  field: FirmwareOrderField;
};

export const FirmwareOrderField = {
  CreatedAt: 'CREATED_AT',
  DeviceTypeName: 'DEVICE_TYPE_NAME',
  PublicKey: 'PUBLIC_KEY',
  UpdatedAt: 'UPDATED_AT',
  Url: 'URL',
  Variant: 'VARIANT',
  Version: 'VERSION'
} as const;

export type FirmwareOrderField = typeof FirmwareOrderField[keyof typeof FirmwareOrderField];
export type FirmwaresConnection = {
  __typename?: 'FirmwaresConnection';
  firmwares: Array<Firmware>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Floor = {
  __typename?: 'Floor';
  building: Building;
  createdAt: Scalars['DateTime']['output'];
  deskStats: DeskStats;
  floorplan?: Maybe<Floorplan>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  /** Number of resources not assigned to a plan for this or any ascending place. */
  unassignedPlanResources: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FloorAttributes = {
  __typename?: 'FloorAttributes';
  position?: Maybe<Scalars['Int']['output']>;
};

export type FloorAttributesInput = {
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type FloorFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  hasNoPlan?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnassignedPlanResources?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if `Floor.name` or `Floor.building.name` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FloorOrder = {
  desc?: Scalars['Boolean']['input'];
  field: FloorOrderField;
};

export const FloorOrderField = {
  BuildingName: 'BUILDING_NAME',
  CreatedAt: 'CREATED_AT',
  DeskStatsTotal: 'DESK_STATS_TOTAL',
  Name: 'NAME',
  Position: 'POSITION',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type FloorOrderField = typeof FloorOrderField[keyof typeof FloorOrderField];
export type FloorSettings = {
  __typename?: 'FloorSettings';
  brokenEquipmentReportEmails?: Maybe<Array<Scalars['String']['output']>>;
  deviceScreenTemplate?: Maybe<DeviceScreenTemplate>;
};

export type FloorSettingsInput = {
  brokenEquipmentReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceScreenTemplate?: InputMaybe<DeviceScreenTemplate>;
};

export type Floorplan = {
  __typename?: 'Floorplan';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mediaId: Scalars['ID']['output'];
  unassignedResources: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FloorsConnection = {
  __typename?: 'FloorsConnection';
  floors: Array<Floor>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GoogleInput = {
  resourceAdmin: Scalars['String']['input'];
  serviceAccountJson: Scalars['String']['input'];
};

export type IdTag = {
  __typename?: 'IDTag';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  tag: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type IdTagOrder = {
  desc?: Scalars['Boolean']['input'];
  field: IdTagOrderField;
};

export const IdTagOrderField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME',
  Tag: 'TAG',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type IdTagOrderField = typeof IdTagOrderField[keyof typeof IdTagOrderField];
export type IdTagsConnection = {
  __typename?: 'IDTagsConnection';
  idTags: Array<IdTag>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Invitation = {
  __typename?: 'Invitation';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  membership: Membership;
  orgId: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  status: InvitationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvitationFilter = {
  /** Checks if `email` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InvitationStatus>>;
};

export type InvitationOrder = {
  desc?: Scalars['Boolean']['input'];
  field: InvitationOrderField;
};

export const InvitationOrderField = {
  CreatedAt: 'CREATED_AT',
  Email: 'EMAIL',
  ExpiresAt: 'EXPIRES_AT',
  Role: 'ROLE',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type InvitationOrderField = typeof InvitationOrderField[keyof typeof InvitationOrderField];
export const InvitationStatus = {
  Accepted: 'ACCEPTED',
  Declined: 'DECLINED',
  Expired: 'EXPIRED',
  Pending: 'PENDING'
} as const;

export type InvitationStatus = typeof InvitationStatus[keyof typeof InvitationStatus];
export type InvitationsConnection = {
  __typename?: 'InvitationsConnection';
  invitations: Array<Invitation>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type License = {
  __typename?: 'License';
  activatesAt?: Maybe<Scalars['DateTime']['output']>;
  count: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  duration: Scalars['Int']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  licenseTypeId: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  refId?: Maybe<Scalars['ID']['output']>;
  /**
   * this assumes a single resource being assigned the license
   * if multiple resources is assigned or `trail` is `true` then
   * `null` is returned
   */
  resource?: Maybe<Resource>;
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  trial: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LicenseFilter = {
  expiresAt?: InputMaybe<TimeWindow>;
  /** @deprecated use `licenseTypes` */
  licenseTypeId?: InputMaybe<Scalars['ID']['input']>;
  licenseTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  onlyAssignable?: InputMaybe<Scalars['Boolean']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LicenseOrder = {
  desc?: Scalars['Boolean']['input'];
  field: LicenseOrderField;
};

export const LicenseOrderField = {
  ActivatesAt: 'ACTIVATES_AT',
  Count: 'COUNT',
  CreatedAt: 'CREATED_AT',
  Duration: 'DURATION',
  ExpiresAt: 'EXPIRES_AT',
  LicenseTypeId: 'LICENSE_TYPE_ID',
  RefId: 'REF_ID',
  RevokedAt: 'REVOKED_AT',
  Trial: 'TRIAL',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type LicenseOrderField = typeof LicenseOrderField[keyof typeof LicenseOrderField];
/** TODO - Currently ever returned as empty value, remove */
export type LicenseStats = {
  __typename?: 'LicenseStats';
  totalDesks: Scalars['Int']['output'];
  totalRooms: Scalars['Int']['output'];
  trialRedeemed: Scalars['Boolean']['output'];
  usedDesks: Scalars['Int']['output'];
  usedRooms: Scalars['Int']['output'];
};

export type LicenseSummary = {
  __typename?: 'LicenseSummary';
  assigned: Scalars['Int']['output'];
  expiresSoon: Scalars['Int']['output'];
  notExpired: Scalars['Int']['output'];
};

export type LicenseSummaryFilter = {
  deviceTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LicensesConnection = {
  __typename?: 'LicensesConnection';
  licenses: Array<License>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Media = {
  __typename?: 'Media';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  originalFileName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export const MeetingProvider = {
  /** Can be used to let the backend decide on which provider to use */
  Auto: 'AUTO',
  GoogleMeets: 'GOOGLE_MEETS',
  MicrosoftTeams: 'MICROSOFT_TEAMS',
  None: 'NONE',
  Unspecified: 'UNSPECIFIED'
} as const;

export type MeetingProvider = typeof MeetingProvider[keyof typeof MeetingProvider];
export type Membership = {
  __typename?: 'Membership';
  calendar?: Maybe<Calendar>;
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated use field calendar instead, null means no calendar */
  hasCalendar: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  /**
   * The organization the user is a member of. May be null if
   * the user does not have access to the organization.
   */
  organization?: Maybe<Organization>;
  role: Role;
  status: MembershipStatus;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type MembershipBookingFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  deskId?: InputMaybe<Scalars['ID']['input']>;
  timeWindow?: InputMaybe<TimeWindow>;
};

export type MembershipFilter = {
  /** Only return memberships that does *not* belong to super admins */
  excludeSuperAdmins?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only return memberships with the specified roles */
  roles?: InputMaybe<Array<Role>>;
  /**
   * Only returns memberships with matching search term.
   * Currently only matches if the search term is contained in the users name.
   */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Only returns memberships with matching status. */
  status?: InputMaybe<Array<MembershipStatus>>;
};

export type MembershipOrder = {
  desc?: Scalars['Boolean']['input'];
  field: MembershipOrderField;
};

export const MembershipOrderField = {
  CreatedAt: 'CREATED_AT',
  Role: 'ROLE',
  UpdatedAt: 'UPDATED_AT',
  UserEmail: 'USER_EMAIL',
  UserName: 'USER_NAME'
} as const;

export type MembershipOrderField = typeof MembershipOrderField[keyof typeof MembershipOrderField];
export type MembershipRoomBookingFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  onlyActiveBookings?: InputMaybe<Scalars['Boolean']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  timeWindow?: InputMaybe<TimeWindow>;
};

export type MembershipStats = {
  __typename?: 'MembershipStats';
  total: Scalars['Int']['output'];
};

export const MembershipStatus = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
  Invited: 'INVITED',
  Requested: 'REQUESTED'
} as const;

export type MembershipStatus = typeof MembershipStatus[keyof typeof MembershipStatus];
export type MembershipsConnection = {
  __typename?: 'MembershipsConnection';
  memberships: Array<Membership>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export const MetricAggregator = {
  Avg: 'AVG',
  First: 'FIRST',
  FirstNotNull: 'FIRST_NOT_NULL',
  Last: 'LAST',
  LastNotNull: 'LAST_NOT_NULL',
  Max: 'MAX',
  Sum: 'SUM'
} as const;

export type MetricAggregator = typeof MetricAggregator[keyof typeof MetricAggregator];
export const MetricDurationUnit = {
  Days: 'DAYS',
  Hours: 'HOURS',
  Minutes: 'MINUTES',
  Months: 'MONTHS',
  Weeks: 'WEEKS',
  Years: 'YEARS'
} as const;

export type MetricDurationUnit = typeof MetricDurationUnit[keyof typeof MetricDurationUnit];
export const MetricInterpolator = {
  Interpolate: 'INTERPOLATE',
  Locf: 'LOCF',
  LocfNullAsMissing: 'LOCF_NULL_AS_MISSING',
  None: 'NONE'
} as const;

export type MetricInterpolator = typeof MetricInterpolator[keyof typeof MetricInterpolator];
export type MetricQueryBucketWidth = {
  unit: MetricDurationUnit;
  value: Scalars['Int']['input'];
};

export type MetricQueryInput = {
  bucketWidth?: MetricQueryBucketWidth;
  metrics: Array<MetricQueryMetricInput>;
  range: TimeWindow;
  timezone?: Scalars['String']['input'];
};

export type MetricQueryMetricInput = {
  aggregator?: MetricAggregator;
  interpolator?: MetricInterpolator;
  label?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type MetricValue = {
  __typename?: 'MetricValue';
  timestamp: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetricVector = {
  __typename?: 'MetricVector';
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  values: Array<MetricValue>;
};

export type MicrosoftInput = {
  clientId: Scalars['String']['input'];
  secret: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCalendarEvent: CalendarEvent;
  acceptInvitation?: Maybe<Invitation>;
  activateLicense: License;
  activateMembership: Membership;
  /**
   * Activate the overview code. This will bind the overview code to the
   * authenticated user and extend the expiration. Can be called multiple times to
   * extend the expiration. If the code can not be activated, one of the following
   * errors will be returned:
   * - `notFound`: the code do not exists or already activated by another user
   * - `expiredToken`: the auth request has expired
   * - `conflict`: if the request is already authorized
   */
  activateOverviewCode: ActivateOverviewCodePayload;
  activateTrialLicense: LicensesConnection;
  addBooking: Booking;
  addBuilding: Building;
  addCalendarSource: CalendarSource;
  addChannel: Channel;
  addDesk: Desk;
  addDeviceStatusEvent: Scalars['Boolean']['output'];
  addDeviceTelemetryEvent: Scalars['Boolean']['output'];
  addDeviceType: DeviceType;
  addFirmware: Firmware;
  addFloor: Floor;
  /**
   * Add ID tag for the given user. Only org. admins are allowed to add tags to other users.
   * User defaults to the subject.
   */
  addIDTag: IdTag;
  addInvitation: Invitation;
  addLicense: License;
  addLicensesToOrg: LicensesConnection;
  addOrganizationMessage: OrganizationMessage;
  addOverview: Overview;
  addPlace: Place;
  addPlaceType: PlaceType;
  addResourceLicense: Resource;
  addResourceType: ResourceType;
  addRoom: Room;
  addRoomBooking: RoomBooking;
  /**
   * Assigns firmware to one or more devices.
   * Narrow down the targeted devices by providing `filter`.
   * Provide `firmwareId` to assign a specific firmware (only devices of the same device type is then targeted).
   * Omit `firmwareId` to assign the latest firmware for the devices respective channel and device type.
   * Returns how many devices were updated.
   */
  assignFirmware: Scalars['Int']['output'];
  /**
   * Authenticate overview screen.
   * If the overview screen is not authorized yet, one of the following errors
   * will be returned:
   * - `notFound`: the device code do not exist
   * - `activationPending`: pending, the user has NOT started the user-interaction steps, continue polling
   * - `authorizationPending`: pending, the user has started the user-interaction steps, continue polling
   * - `slowDown`: polling to fast, increase interval by 5 seconds for this and all subsequent requests, continue polling
   * - `accessDenied`: the auth request has been rejected
   * - `expiredToken`: the auth request has expired
   */
  authenticateWithOverviewCode: AuthenticatePayload;
  /**
   * Endpoint for letting administrators authorize a screen to obtain a token to
   * show an overview screen. If authorization fails, one of the following errors
   * will be returned:
   * - `notFound`: the code do not exists or already activated by another user
   * - `expiredToken`: the auth request has expired
   * - `conflict`: if the request is already authorized
   */
  authorizeOverview: Overview;
  cancelBooking: Booking;
  cancelRoomBooking: RoomBooking;
  checkInBooking: Booking;
  checkInRoomBooking: RoomBooking;
  checkOutBooking: Booking;
  checkOutRoomBooking: RoomBooking;
  createCalendarEvent: CalendarEvent;
  createDevice: CreateDevicePayload;
  createOrganization: Organization;
  createPlan: Plan;
  deactivateMembership: Membership;
  declineInvitation: Invitation;
  deleteBuilding: Building;
  deleteCalendarEvent: DeleteCalendarEventResponse;
  deleteCalendarSource: DeleteCalendarSourceResponse;
  deleteChannel: Channel;
  deleteDesk: Desk;
  deleteDevice: Device;
  deleteFirmware: Firmware;
  deleteFloor: Floor;
  /** Remove ID tag */
  deleteIDTag: IdTag;
  deleteInvitation: Invitation;
  deleteMembership: Membership;
  deleteOrganization: Organization;
  deleteOrganizationMessage: OrganizationMessage;
  deleteOverview: Overview;
  deletePlace: Place;
  deletePlan: Plan;
  deleteResourceLicense: Resource;
  deleteRoom: Room;
  deleteUser: User;
  deviceCommand: DeviceCommandResult;
  disconnectResourceCalendar: Resource;
  extendBooking: Booking;
  /** Generate a new overview code used to authorize overview screens. */
  generateOverviewCode: OverviewCodePayload;
  joinOrganization: Membership;
  publishNatsAccount: NatsAccount;
  reportResourceIssue: Scalars['Boolean']['output'];
  revokeLicense: License;
  signDeviceJwt: DeviceJwt;
  startSyncOrgSources: StartSyncOrgSourcesResponse;
  updateBooking: Booking;
  updateBuilding: Building;
  updateCalendarEvent: CalendarEvent;
  updateCalendarSource: CalendarSource;
  updateDesk: Desk;
  updateDevice: Device;
  /** @deprecated succeeded by `addDeviceTelemetryEvent` */
  updateDeviceStatus: DeviceStatus;
  updateDeviceType: DeviceType;
  updateFirmware: Firmware;
  updateFloor: Floor;
  updateInvitation: Invitation;
  updateLicense: License;
  updateMembership: Membership;
  /**
   * Set the user ID that should be associated with a specific FCM token.
   * If user ID is null, the FCM token will be removed.
   */
  updateNotificationToken: Scalars['Boolean']['output'];
  updateOrganization: Organization;
  updateOrganizationMessage: OrganizationMessage;
  updateOverview: Overview;
  updatePlace: Place;
  updatePlan: Plan;
  updateProfile: User;
  updateRoom: Room;
  updateRoomBooking: RoomBooking;
};


export type MutationAcceptCalendarEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAcceptInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationActivateLicenseArgs = {
  activationTime?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};


export type MutationActivateMembershipArgs = {
  id: Scalars['ID']['input'];
};


export type MutationActivateOverviewCodeArgs = {
  userCode: Scalars['String']['input'];
};


export type MutationActivateTrialLicenseArgs = {
  activationTime?: InputMaybe<Scalars['DateTime']['input']>;
  orgId: Scalars['ID']['input'];
};


export type MutationAddBookingArgs = {
  idTag?: InputMaybe<Scalars['String']['input']>;
  input: AddBookingInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddBuildingArgs = {
  input: AddBuildingInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddCalendarSourceArgs = {
  input: AddCalendarSourceInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddChannelArgs = {
  input: AddChannelInput;
};


export type MutationAddDeskArgs = {
  input: AddDeskInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddDeviceStatusEventArgs = {
  data: DeviceEventStatusInput;
  deviceId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};


export type MutationAddDeviceTelemetryEventArgs = {
  data: DeviceEventTelemetryInput;
  deviceId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};


export type MutationAddDeviceTypeArgs = {
  input: AddDeviceTypeInput;
};


export type MutationAddFirmwareArgs = {
  input: AddFirmwareInput;
};


export type MutationAddFloorArgs = {
  buildingId: Scalars['ID']['input'];
  input: AddFloorInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddIdTagArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  tag: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddInvitationArgs = {
  input: AddInvitationInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddLicenseArgs = {
  input: AddLicenseInput;
};


export type MutationAddLicensesToOrgArgs = {
  input: AddLicensesInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddOrganizationMessageArgs = {
  input: AddOrganizationMessageInput;
};


export type MutationAddOverviewArgs = {
  input: AddOverviewInput;
};


export type MutationAddPlaceArgs = {
  input: AddPlaceInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddPlaceTypeArgs = {
  input: AddPlaceTypeInput;
};


export type MutationAddResourceLicenseArgs = {
  id: Scalars['ID']['input'];
  licenseId: Scalars['ID']['input'];
};


export type MutationAddResourceTypeArgs = {
  input: AddResourceTypeInput;
};


export type MutationAddRoomArgs = {
  input: AddRoomInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddRoomBookingArgs = {
  idTag?: InputMaybe<Scalars['String']['input']>;
  input: AddRoomBookingInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAssignFirmwareArgs = {
  filter: AssignFirmwareFilter;
  firmwareId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationAuthenticateWithOverviewCodeArgs = {
  deviceCode: Scalars['String']['input'];
};


export type MutationAuthorizeOverviewArgs = {
  orgId: Scalars['ID']['input'];
  overviewId: Scalars['String']['input'];
  userCode: Scalars['String']['input'];
};


export type MutationCancelBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelRoomBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};


export type MutationCheckInBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCheckInRoomBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCheckOutBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCheckOutRoomBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};


export type MutationCreateCalendarEventArgs = {
  input: CreateCalendarEventInput;
  membershipId: Scalars['ID']['input'];
};


export type MutationCreateDeviceArgs = {
  input: DeviceInput;
  orgId: Scalars['ID']['input'];
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
  orgId: Scalars['ID']['input'];
};


export type MutationDeactivateMembershipArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeclineInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBuildingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCalendarEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCalendarSourceArgs = {
  srcId: Scalars['ID']['input'];
};


export type MutationDeleteChannelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDeskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFirmwareArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFloorArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteIdTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMembershipArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationMessageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOverviewArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePlaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePlanArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteResourceLicenseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRoomArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeviceCommandArgs = {
  input: DeviceCommandInput;
};


export type MutationDisconnectResourceCalendarArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExtendBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  seconds?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationJoinOrganizationArgs = {
  domain: Scalars['String']['input'];
};


export type MutationPublishNatsAccountArgs = {
  id: Scalars['String']['input'];
};


export type MutationReportResourceIssueArgs = {
  id: Scalars['ID']['input'];
  problemAreas: Array<RoomResource>;
};


export type MutationRevokeLicenseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSignDeviceJwtArgs = {
  input: SignDeviceJwtInput;
};


export type MutationStartSyncOrgSourcesArgs = {
  orgId: Scalars['ID']['input'];
};


export type MutationUpdateBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  input: UpdateBookingInput;
};


export type MutationUpdateBuildingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBuildingInput;
};


export type MutationUpdateCalendarEventArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCalendarEventInput;
};


export type MutationUpdateCalendarSourceArgs = {
  input: UpdateCalendarSourceInput;
  srcId: Scalars['ID']['input'];
};


export type MutationUpdateDeskArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDeskInput;
};


export type MutationUpdateDeviceArgs = {
  id?: Scalars['ID']['input'];
  input: UpdateDeviceInput;
};


export type MutationUpdateDeviceStatusArgs = {
  id?: Scalars['ID']['input'];
  input: DeviceStatusInput;
};


export type MutationUpdateDeviceTypeArgs = {
  input: UpdateDeviceTypeInput;
};


export type MutationUpdateFirmwareArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFirmwareInput;
};


export type MutationUpdateFloorArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFloorInput;
};


export type MutationUpdateInvitationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateInvitationInput;
};


export type MutationUpdateLicenseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLicenseInput;
};


export type MutationUpdateMembershipArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMembershipInput;
};


export type MutationUpdateNotificationTokenArgs = {
  fcmToken: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationMessageArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrganizationMessageInput;
};


export type MutationUpdateOverviewArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOverviewInput;
};


export type MutationUpdatePlaceArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePlaceInput;
};


export type MutationUpdatePlanArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePlanInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateRoomArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRoomInput;
};


export type MutationUpdateRoomBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  input: UpdateRoomBookingInput;
  orgId: Scalars['ID']['input'];
};

export type NatsAccount = {
  __typename?: 'NatsAccount';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  jwt: Scalars['String']['output'];
  orgId?: Maybe<Scalars['ID']['output']>;
  platformId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OpenHours = {
  __typename?: 'OpenHours';
  closed: Scalars['Boolean']['output'];
  from: Scalars['TimeOfDay']['output'];
  until: Scalars['TimeOfDay']['output'];
};

export type OpenHoursInput = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['TimeOfDay']['input']>;
  until?: InputMaybe<Scalars['TimeOfDay']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  bookingSettings: OrganizationBookingSettings;
  brokenEquipmentReportEmails?: Maybe<Array<Scalars['String']['output']>>;
  calendarSettings: CalendarSettings;
  createdAt: Scalars['DateTime']['output'];
  deskBookingSettings: DeskBookingSettings;
  deskStats: DeskStats;
  deviceErrorReportEmails?: Maybe<Array<Scalars['String']['output']>>;
  deviceErrorReportWebhookUrl?: Maybe<Scalars['String']['output']>;
  deviceScreenTemplate: DeviceScreenTemplate;
  deviceStats: DeviceStats;
  domain: Scalars['String']['output'];
  hasCalendar: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  licenseStats: LicenseStats;
  logoMediaId?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  membershipStats: MembershipStats;
  name: Scalars['String']['output'];
  /** @deprecated legacy, will be removed */
  ola?: Maybe<Scalars['String']['output']>;
  openHours: WeeklyOpenHours;
  roomBookingSettings: RoomBookingSettings;
  timePresentation: TimePresentation;
  timezone: Scalars['String']['output'];
  trial: Array<License>;
  updatedAt: Scalars['DateTime']['output'];
  whitelist?: Maybe<Scalars['String']['output']>;
  whitelistEnabled: Scalars['Boolean']['output'];
};

export type OrganizationBookingSettings = {
  __typename?: 'OrganizationBookingSettings';
  privateOnly: Scalars['Boolean']['output'];
};

export type OrganizationBookingSettingsInput = {
  privateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationFilter = {
  onlyActiveTrial?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationMessage = {
  __typename?: 'OrganizationMessage';
  canBeClosed: Scalars['Boolean']['output'];
  content: Scalars['String']['output'];
  hideAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  organizations: Array<Organization>;
  showAt?: Maybe<Scalars['DateTime']['output']>;
  showToAllOrganizations: Scalars['Boolean']['output'];
  type: OrganizationMessageType;
};

export type OrganizationMessageFilter = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export type OrganizationMessageOrder = {
  desc?: Scalars['Boolean']['input'];
  field: OrganizationMessageOrderField;
};

export const OrganizationMessageOrderField = {
  CanBeClosed: 'CAN_BE_CLOSED',
  Content: 'CONTENT',
  HideAt: 'HIDE_AT',
  ShowAt: 'SHOW_AT',
  Type: 'TYPE'
} as const;

export type OrganizationMessageOrderField = typeof OrganizationMessageOrderField[keyof typeof OrganizationMessageOrderField];
export const OrganizationMessageType = {
  Error: 'ERROR',
  Info: 'INFO',
  Success: 'SUCCESS',
  Warning: 'WARNING'
} as const;

export type OrganizationMessageType = typeof OrganizationMessageType[keyof typeof OrganizationMessageType];
export type OrganizationMessagesConnection = {
  __typename?: 'OrganizationMessagesConnection';
  organizationMessages: Array<OrganizationMessage>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationOrder = {
  desc?: Scalars['Boolean']['input'];
  field: OrganizationOrderField;
};

export const OrganizationOrderField = {
  CreatedAt: 'CREATED_AT',
  DeskStatsTotal: 'DESK_STATS_TOTAL',
  DeviceStatsTotal: 'DEVICE_STATS_TOTAL',
  Domain: 'DOMAIN',
  MembershipStatsTotal: 'MEMBERSHIP_STATS_TOTAL',
  Name: 'NAME',
  Ola: 'OLA',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type OrganizationOrderField = typeof OrganizationOrderField[keyof typeof OrganizationOrderField];
export type OrganizationsConnection = {
  __typename?: 'OrganizationsConnection';
  organizations: Array<Organization>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Overview = {
  __typename?: 'Overview';
  columnCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  floor?: Maybe<Floor>;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  showDateTime: Scalars['Boolean']['output'];
  showLogo: Scalars['Boolean']['output'];
  tiles: Array<Tile>;
  type: OverviewType;
  updatedAt: Scalars['DateTime']['output'];
};

export type OverviewCodePayload = {
  __typename?: 'OverviewCodePayload';
  deviceCode: Scalars['String']['output'];
  expiresIn: Scalars['Duration']['output'];
  interval: Scalars['Duration']['output'];
  userCode: Scalars['String']['output'];
  verificationUri: Scalars['String']['output'];
  verificationUriComplete?: Maybe<Scalars['String']['output']>;
};

export type OverviewFilter = {
  /** Checks if `name` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type OverviewOrder = {
  desc?: Scalars['Boolean']['input'];
  field: OverviewOrderField;
};

export const OverviewOrderField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type OverviewOrderField = typeof OverviewOrderField[keyof typeof OverviewOrderField];
export const OverviewType = {
  Floorplan: 'FLOORPLAN',
  Tiles: 'TILES'
} as const;

export type OverviewType = typeof OverviewType[keyof typeof OverviewType];
export type OverviewsConnection = {
  __typename?: 'OverviewsConnection';
  overviews: Array<Overview>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type ParkingLotAttributes = {
  __typename?: 'ParkingLotAttributes';
  address?: Maybe<Scalars['String']['output']>;
  spots?: Maybe<Scalars['Int']['output']>;
};

export type ParkingLotAttributesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  spots?: InputMaybe<Scalars['Int']['input']>;
};

export type Place = {
  __typename?: 'Place';
  attributes?: Maybe<PlaceAttributes>;
  createdAt: Scalars['DateTime']['output'];
  deviceCount?: Maybe<Scalars['Int']['output']>;
  fullName: Array<Scalars['String']['output']>;
  hasChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentPlaceId?: Maybe<Scalars['ID']['output']>;
  placeTypeId: Scalars['ID']['output'];
  plan?: Maybe<Plan>;
  resourceCount?: Maybe<Scalars['Int']['output']>;
  settings?: Maybe<PlaceSettings>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PlaceAttributes = BuildingAttributes | FloorAttributes | ParkingLotAttributes | SiteAttributes;

export type PlaceAttributesInput = {
  BUILDING?: InputMaybe<BuildingAttributesInput>;
  FLOOR?: InputMaybe<FloorAttributesInput>;
  PARKING_LOT?: InputMaybe<ParkingLotAttributesInput>;
  SITE?: InputMaybe<SiteAttributesInput>;
};

export type PlaceFilter = {
  hasNoParent?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoPlan?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnassignedPlanResources?: InputMaybe<Scalars['Boolean']['input']>;
  parentPlaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  placeTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Checks if `name` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type PlaceOrder = {
  desc?: Scalars['Boolean']['input'];
  field: PlaceOrderField;
};

export const PlaceOrderField = {
  CreatedAt: 'CREATED_AT',
  DeviceCount: 'DEVICE_COUNT',
  Name: 'NAME',
  PlaceTypeId: 'PLACE_TYPE_ID',
  ResourceCount: 'RESOURCE_COUNT',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type PlaceOrderField = typeof PlaceOrderField[keyof typeof PlaceOrderField];
export type PlaceSettings = BuildingSettings | FloorSettings | SiteSettings | ZoneSettings;

export type PlaceSettingsInput = {
  BUILDING?: InputMaybe<BuildingSettingsInput>;
  FLOOR?: InputMaybe<FloorSettingsInput>;
  SITE?: InputMaybe<SiteSettingsInput>;
  ZONE?: InputMaybe<ZoneSettingsInput>;
};

export type PlaceType = {
  __typename?: 'PlaceType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PlacesConnection = {
  __typename?: 'PlacesConnection';
  pageInfo: PageInfo;
  places: Array<Place>;
  totalCount: Scalars['Int']['output'];
};

export type Plan = {
  __typename?: 'Plan';
  annotations: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mediaId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  placeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  allBookings: BookingsConnection;
  allBuildings: BuildingsConnection;
  allCalendarEvents: CalendarEventsConnection;
  allCalendarSources: CalendarSourcesConnection;
  allCalendars: CalendarsConnection;
  allChannels: ChannelsConnection;
  allColleagues: ColleaguesConnection;
  allDesks: DesksConnection;
  allDeviceEvents: DeviceEventConnection;
  allDeviceTypes: Array<DeviceType>;
  allDevices: DevicesConnection;
  allFirmwares: FirmwaresConnection;
  allFloors: FloorsConnection;
  allIDTags: IdTagsConnection;
  allInvitations: InvitationsConnection;
  allLicenses: LicensesConnection;
  allMemberships: MembershipsConnection;
  allOrganizationMessages: OrganizationMessagesConnection;
  allOrganizations: OrganizationsConnection;
  allOverviews: OverviewsConnection;
  allPlaceTypes?: Maybe<Array<PlaceType>>;
  allPlaces: PlacesConnection;
  allResourceTypes?: Maybe<Array<ResourceType>>;
  allResources: ResourcesConnection;
  /** @deprecated use allBookings */
  allRoomBookings: RoomBookingsConnection;
  allRooms: RoomsConnection;
  /** Returns list of all users */
  allUsers: UsersConnection;
  building: Building;
  calendar: Calendar;
  calendarEvent: CalendarEvent;
  calendarSource: CalendarSource;
  desk?: Maybe<Desk>;
  device: Device;
  deviceMetrics: Array<MetricVector>;
  deviceStatistics?: Maybe<DeviceStatistics>;
  floor: Floor;
  licenseSummary?: Maybe<LicenseSummary>;
  membership: Membership;
  organization: Organization;
  overview: Overview;
  place: Place;
  profile: User;
  resource: Resource;
  resourceStatistics: ResourceStatisticsConnection;
  resourceSummary: ResourceSummary;
  room: Room;
  userByIdTag: User;
};


export type QueryAllBookingsArgs = {
  filter?: InputMaybe<BookingFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<BookingOrder>>;
};


export type QueryAllBuildingsArgs = {
  filter?: InputMaybe<BuildingFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<BuildingOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllCalendarEventsArgs = {
  filter?: InputMaybe<CalendarEventsFilter>;
  membershipId: Scalars['ID']['input'];
  order?: Array<CalendarEventOrder>;
  pageSize?: Scalars['Int']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCalendarSourcesArgs = {
  orgId: Scalars['ID']['input'];
  pageSize?: Scalars['Int']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCalendarsArgs = {
  filter?: InputMaybe<CalendarsFilter>;
  orgId: Scalars['ID']['input'];
  pageSize?: Scalars['Int']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllChannelsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  order?: InputMaybe<Array<ChannelOrder>>;
};


export type QueryAllColleaguesArgs = {
  filter?: InputMaybe<ColleagueFilter>;
  orgId: Scalars['ID']['input'];
  pageSize?: Scalars['Int']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllDesksArgs = {
  filter?: InputMaybe<DeskFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<DeskOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllDeviceEventsArgs = {
  filter?: InputMaybe<DeviceEventFilter>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<DeviceEventOrder>>;
};


export type QueryAllDevicesArgs = {
  filter?: InputMaybe<DeviceFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<DeviceOrder>>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllFirmwaresArgs = {
  channelId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<FirmwareFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<FirmwareOrder>>;
};


export type QueryAllFloorsArgs = {
  filter?: InputMaybe<FloorFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<FloorOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllIdTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IdTagOrder>>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllInvitationsArgs = {
  filter?: InputMaybe<InvitationFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InvitationOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllLicensesArgs = {
  filter?: InputMaybe<LicenseFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<LicenseOrder>>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllMembershipsArgs = {
  filter?: InputMaybe<MembershipFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<MembershipOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllOrganizationMessagesArgs = {
  filter?: InputMaybe<OrganizationMessageFilter>;
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrganizationMessageOrder>>;
};


export type QueryAllOrganizationsArgs = {
  filter?: InputMaybe<OrganizationFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrganizationOrder>>;
};


export type QueryAllOverviewsArgs = {
  filter?: InputMaybe<OverviewFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OverviewOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllPlacesArgs = {
  filter?: InputMaybe<PlaceFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PlaceOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllResourcesArgs = {
  filter?: InputMaybe<ResourceFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ResourceOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllRoomBookingsArgs = {
  filter?: InputMaybe<RoomBookingFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<RoomBookingOrder>>;
};


export type QueryAllRoomsArgs = {
  filter?: InputMaybe<RoomFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<RoomOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UserOrder>>;
};


export type QueryBuildingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCalendarArgs = {
  membershipId: Scalars['ID']['input'];
};


export type QueryCalendarEventArgs = {
  eventId: Scalars['ID']['input'];
};


export type QueryCalendarSourceArgs = {
  srcId: Scalars['ID']['input'];
};


export type QueryDeskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDeviceArgs = {
  id?: Scalars['ID']['input'];
};


export type QueryDeviceMetricsArgs = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  input?: InputMaybe<MetricQueryInput>;
  orgId: Scalars['ID']['input'];
};


export type QueryDeviceStatisticsArgs = {
  filter?: InputMaybe<DeviceStatisticsFilter>;
  orgId: Scalars['ID']['input'];
};


export type QueryFloorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLicenseSummaryArgs = {
  filter?: InputMaybe<LicenseSummaryFilter>;
  orgId: Scalars['ID']['input'];
};


export type QueryMembershipArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOverviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlaceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryResourceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryResourceStatisticsArgs = {
  filter: ResourceStatisticsFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ResourceStatisticsOrder>>;
};


export type QueryResourceSummaryArgs = {
  filter: ResourceSummaryFilter;
};


export type QueryRoomArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserByIdTagArgs = {
  idTag: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
};

export type Resource = {
  __typename?: 'Resource';
  attributes: ResourceAttributes;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  license?: Maybe<License>;
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  place?: Maybe<Place>;
  /** @deprecated use place.id */
  placeId?: Maybe<Scalars['ID']['output']>;
  remoteId?: Maybe<Scalars['String']['output']>;
  resourceTypeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ResourceAttributes = {
  __typename?: 'ResourceAttributes';
  desk?: Maybe<DeskAttributes>;
  room?: Maybe<RoomAttributes>;
};

export type ResourceDistribution = {
  __typename?: 'ResourceDistribution';
  count: Scalars['Int']['output'];
  hour: Scalars['Int']['output'];
};

export type ResourceFilter = {
  places?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ResourceOrder = {
  desc?: Scalars['Boolean']['input'];
  field: ResourceOrderField;
};

export const ResourceOrderField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME',
  TypeName: 'TYPE_NAME',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type ResourceOrderField = typeof ResourceOrderField[keyof typeof ResourceOrderField];
export type ResourceStatistics = {
  __typename?: 'ResourceStatistics';
  bookedCount: Scalars['Int']['output'];
  bookedSeconds: Scalars['Int']['output'];
  capacity?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  notCheckedInCount: Scalars['Int']['output'];
  resourceId: Scalars['ID']['output'];
  resourceTypeId: Scalars['String']['output'];
};

export type ResourceStatisticsConnection = {
  __typename?: 'ResourceStatisticsConnection';
  pageInfo: PageInfo;
  statistics: Array<ResourceStatistics>;
  totalCount: Scalars['Int']['output'];
};

export type ResourceStatisticsFilter = {
  orgId: Scalars['ID']['input'];
  places?: InputMaybe<Array<Scalars['ID']['input']>>;
  resourceTypeId?: InputMaybe<BookingResourceTypeId>;
  timeWindow: TimeWindow;
};

export type ResourceStatisticsOrder = {
  desc?: Scalars['Boolean']['input'];
  field: ResourceStatisticsOrderField;
};

export const ResourceStatisticsOrderField = {
  BookingCount: 'BOOKING_COUNT',
  BookingTime: 'BOOKING_TIME',
  Capacity: 'CAPACITY',
  NotCheckedIn: 'NOT_CHECKED_IN',
  ResourceType: 'RESOURCE_TYPE'
} as const;

export type ResourceStatisticsOrderField = typeof ResourceStatisticsOrderField[keyof typeof ResourceStatisticsOrderField];
export type ResourceSummary = {
  __typename?: 'ResourceSummary';
  activeUserCount: Scalars['Int']['output'];
  distribution?: Maybe<Array<ResourceDistribution>>;
  totalBookingCount: Scalars['Int']['output'];
  totalBookingSeconds: Scalars['Int']['output'];
  totalUserCount: Scalars['Int']['output'];
};

export type ResourceSummaryFilter = {
  /** ISO Day Of Week, Monday as 1 to Sunday as 7. Only used for distributions. */
  isoDoW?: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  places?: InputMaybe<Array<Scalars['ID']['input']>>;
  resourceTypeId?: InputMaybe<BookingResourceTypeId>;
  timeWindow: TimeWindow;
};

export type ResourceType = {
  __typename?: 'ResourceType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ResourcesConnection = {
  __typename?: 'ResourcesConnection';
  pageInfo: PageInfo;
  resources: Array<Resource>;
  totalCount: Scalars['Int']['output'];
};

export const Role = {
  DeskAdmin: 'DESK_ADMIN',
  OrgAdmin: 'ORG_ADMIN',
  Owner: 'OWNER',
  Unspecified: 'UNSPECIFIED',
  User: 'USER'
} as const;

export type Role = typeof Role[keyof typeof Role];
export type Room = {
  __typename?: 'Room';
  /**
   * Contains the available timespan for this room within the requested period.
   * Note: this is always `null` if not requested with `AvailableFilter`, e.g.
   * using `allRooms` query.
   */
  available?: Maybe<Availability>;
  bookingSettings: RoomBookingSettings;
  brokenEquipmentReportEmails?: Maybe<Array<Scalars['String']['output']>>;
  capacity: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The currently active booking, if any */
  currentBooking?: Maybe<RoomBooking>;
  deviceScreenTemplate?: Maybe<DeviceScreenTemplate>;
  email?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Floor>;
  floorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  license?: Maybe<License>;
  media?: Maybe<Media>;
  name: Scalars['String']['output'];
  /** The next upcoming booking, if any */
  nextBooking?: Maybe<RoomBooking>;
  orgId: Scalars['ID']['output'];
  resources: Array<RoomResource>;
  roomType?: Maybe<Scalars['String']['output']>;
  /**
   * Time presentation used for this room. The value is picked from the building
   * with the organization default as fallback.
   */
  timePresentation: TimePresentation;
  /**
   * Time zone of this room. The time zone is picked from the building with
   * the organization default as fallback.
   */
  timeZone: Scalars['String']['output'];
  /** Time offset from UTC in seconds */
  timeZoneOffset: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RoomAttributes = {
  __typename?: 'RoomAttributes';
  capacity?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type RoomBooking = {
  __typename?: 'RoomBooking';
  attendees: Array<Attendee>;
  bookedBy: Scalars['String']['output'];
  canceled?: Maybe<Scalars['DateTime']['output']>;
  checkedIn?: Maybe<Scalars['DateTime']['output']>;
  checkedOut?: Maybe<Scalars['DateTime']['output']>;
  endRecurringDate?: Maybe<Scalars['DateTime']['output']>;
  endTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isImmutable?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  isWholeDay?: Maybe<Scalars['Boolean']['output']>;
  recurring?: Maybe<Scalars['String']['output']>;
  room: Room;
  startTime: Scalars['DateTime']['output'];
  teamsMeeting?: Maybe<Scalars['Boolean']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type RoomBookingAttributes = {
  __typename?: 'RoomBookingAttributes';
  attendees: Array<Attendee>;
  isImmutable?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  organizer: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type RoomBookingEdge = {
  __typename?: 'RoomBookingEdge';
  node: RoomBooking;
};

export type RoomBookingEvent = {
  __typename?: 'RoomBookingEvent';
  added?: Maybe<RoomBooking>;
  deleted?: Maybe<RoomBooking>;
  updated?: Maybe<RoomBooking>;
};

export type RoomBookingFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  iCalUID?: InputMaybe<BookingFilterICalUid>;
  onlyActiveBookings?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  timeWindow?: InputMaybe<TimeWindow>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type RoomBookingOrder = {
  desc?: Scalars['Boolean']['input'];
  field: RoomBookingOrderField;
};

export const RoomBookingOrderField = {
  CreatedAt: 'CREATED_AT',
  EndTime: 'END_TIME',
  OrganizationName: 'ORGANIZATION_NAME',
  RoomName: 'ROOM_NAME',
  StartTime: 'START_TIME',
  UpdatedAt: 'UPDATED_AT',
  UserName: 'USER_NAME'
} as const;

export type RoomBookingOrderField = typeof RoomBookingOrderField[keyof typeof RoomBookingOrderField];
export type RoomBookingSettings = {
  __typename?: 'RoomBookingSettings';
  /**
   * If true, the desk device will automatically check-in a current booking on presence detection
   * @deprecated desk specific setting, will be removed for rooms
   */
  autoCheckInOnPresence: Scalars['Boolean']['output'];
  bookingWindowEnds: Scalars['TimeOfDay']['output'];
  bookingWindowStarts: Scalars['TimeOfDay']['output'];
  /** How many calendar days in advance you may place a booking */
  daysInAdvanceLimit: Scalars['Int']['output'];
  durationLimit: Scalars['Duration']['output'];
  /** Period of time, before start of booking, check-in is allowed. */
  earlyCheckIn: Scalars['Duration']['output'];
  /** The initial/default booking duration */
  initialDuration: Scalars['Duration']['output'];
  /** If the user has not showed up after this delay, the booking is canceled. */
  lateArrivalCancelDelay: Scalars['Duration']['output'];
  /** If the user has not showed up after this delay, a late-arrival notice is pushed to the user */
  lateArrivalReminderDelay: Scalars['Duration']['output'];
  /** If true, the booker of a booking is private for all bookings. */
  privateOnly: Scalars['Boolean']['output'];
  /** If true, user are allowed to check-in using qr code in the app. */
  qrCodeCheckIn: Scalars['Boolean']['output'];
  /** If true, users are allowed to check-in remotely using the app */
  remoteCheckIn: Scalars['Boolean']['output'];
  /**
   * If true, the room device will require identification with RFID tag to allow
   * the user to book the desk.
   */
  requireIdentForBooking: Scalars['Boolean']['output'];
  /** If true, the user must identify with RFID tag to check in on the room device */
  requireIdentForCheckIn: Scalars['Boolean']['output'];
  /** If true, the user must identify with RFID tag to extend or check-out a booking */
  requireIdentForUpdating: Scalars['Boolean']['output'];
};

export type RoomBookingSettingsInput = {
  /** @deprecated desk specific setting, will be removed for rooms */
  autoCheckInOnPresence?: InputMaybe<Scalars['Boolean']['input']>;
  bookingWindowEnds?: InputMaybe<Scalars['TimeOfDay']['input']>;
  bookingWindowStarts?: InputMaybe<Scalars['TimeOfDay']['input']>;
  daysInAdvanceLimit?: InputMaybe<Scalars['Int']['input']>;
  durationLimit?: InputMaybe<Scalars['Duration']['input']>;
  earlyCheckIn?: InputMaybe<Scalars['Duration']['input']>;
  initialDuration?: InputMaybe<Scalars['Duration']['input']>;
  lateArrivalCancelDelay?: InputMaybe<Scalars['Duration']['input']>;
  lateArrivalReminderDelay?: InputMaybe<Scalars['Duration']['input']>;
  qrCodeCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  remoteCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForBooking?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForUpdating?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoomBookingsConnection = {
  __typename?: 'RoomBookingsConnection';
  bookings: Array<RoomBooking>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RoomEdge = {
  __typename?: 'RoomEdge';
  node: Room;
};

export type RoomFilter = {
  /** Note: this filter can not be combined with `RoomOrder` when querying. */
  available?: InputMaybe<AvailableFilter>;
  floors?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Checks if `name` or `email` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type RoomOrder = {
  desc?: Scalars['Boolean']['input'];
  field: RoomOrderField;
};

export const RoomOrderField = {
  Capacity: 'CAPACITY',
  CreatedAt: 'CREATED_AT',
  Email: 'EMAIL',
  Name: 'NAME',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type RoomOrderField = typeof RoomOrderField[keyof typeof RoomOrderField];
export const RoomResource = {
  Camera: 'CAMERA',
  Connectivity: 'CONNECTIVITY',
  ControlPanel: 'CONTROL_PANEL',
  Display: 'DISPLAY',
  Furniture: 'FURNITURE',
  Lights: 'LIGHTS',
  Network: 'NETWORK',
  Other: 'OTHER',
  Phone: 'PHONE',
  ProjectorScreen: 'PROJECTOR_SCREEN',
  RoomTemperature: 'ROOM_TEMPERATURE',
  SoundMicrophones: 'SOUND_MICROPHONES',
  Speaker: 'SPEAKER',
  Whiteboard: 'WHITEBOARD'
} as const;

export type RoomResource = typeof RoomResource[keyof typeof RoomResource];
export type RoomsConnection = {
  __typename?: 'RoomsConnection';
  pageInfo: PageInfo;
  rooms: Array<Room>;
  totalCount: Scalars['Int']['output'];
};

export type SettingsInput = {
  google?: InputMaybe<GoogleInput>;
  microsoft?: InputMaybe<MicrosoftInput>;
};

export type SignDeviceJwtInput = {
  deviceId: Scalars['String']['input'];
  devicePublicKey: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type SiteAttributes = {
  __typename?: 'SiteAttributes';
  address?: Maybe<Scalars['String']['output']>;
};

export type SiteAttributesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
};

export type SiteSettings = {
  __typename?: 'SiteSettings';
  openHours?: Maybe<WeeklyOpenHours>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type SiteSettingsInput = {
  openHours?: InputMaybe<WeeklyOpenHoursInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type StartSyncOrgSourcesResponse = {
  __typename?: 'StartSyncOrgSourcesResponse';
  started: Scalars['Boolean']['output'];
};

export type Subject = Overview;

export type Subscription = {
  __typename?: 'Subscription';
  bookings: BookingEvent;
  deskUpdate: Desk;
  deviceUpdate: Device;
  roomBookings: RoomBookingEvent;
  roomUpdate: Room;
};


export type SubscriptionBookingsArgs = {
  deskId: Scalars['ID']['input'];
};


export type SubscriptionDeskUpdateArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionDeviceUpdateArgs = {
  id?: Scalars['ID']['input'];
};


export type SubscriptionRoomBookingsArgs = {
  roomId: Scalars['ID']['input'];
};


export type SubscriptionRoomUpdateArgs = {
  id: Scalars['ID']['input'];
};

export type TesiraAttributes = {
  __typename?: 'TesiraAttributes';
  engineeringLogs?: Maybe<TesiraEngineeringLogs>;
  launch?: Maybe<TesiraLaunch>;
};

export type TesiraEngineeringLogs = {
  __typename?: 'TesiraEngineeringLogs';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  requestedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type TesiraLaunch = {
  __typename?: 'TesiraLaunch';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  longUrl?: Maybe<Scalars['String']['output']>;
  requestedAt: Scalars['DateTime']['output'];
  shortUrl?: Maybe<Scalars['String']['output']>;
};

/** This represents a tile for an overview screen */
export type Tile = {
  __typename?: 'Tile';
  directionIndicator?: Maybe<Direction>;
  floor?: Maybe<Floor>;
  floorId?: Maybe<Scalars['ID']['output']>;
  hideBookingInformation?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  room?: Maybe<Room>;
  roomId?: Maybe<Scalars['ID']['output']>;
  type: TileType;
};

export const TileType = {
  Blank: 'BLANK',
  Desk: 'DESK',
  Room: 'ROOM'
} as const;

export type TileType = typeof TileType[keyof typeof TileType];
export const TimePresentation = {
  H12: 'H12',
  H24: 'H24'
} as const;

export type TimePresentation = typeof TimePresentation[keyof typeof TimePresentation];
export type TimeWindow = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type UpdateBookingInput = {
  arrivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  deskId?: InputMaybe<Scalars['ID']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  focusMode?: InputMaybe<Scalars['Boolean']['input']>;
  skipAwayFromDeskReminder?: InputMaybe<Scalars['Boolean']['input']>;
  skipLateArrivalReminder?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateBuildingInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  brokenEquipmentReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceScreenTemplate?: InputMaybe<DeviceScreenTemplate>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openHours?: InputMaybe<WeeklyOpenHoursInput>;
  overrideOpenHours?: InputMaybe<Scalars['Boolean']['input']>;
  timePresentation?: InputMaybe<TimePresentation>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

/**
 * TODO: Make attendees nullable
 * Requires changing the protobuf definition of CalendarEvents to include a wrapper for the slice since
 * it cannot detect differences between empty slices and nil
 * https://stackoverflow.com/questions/21631428/protobuf-net-deserializes-empty-collection-to-null-when-the-collection-is-a-prop#answer-21632160
 */
export type UpdateCalendarEventInput = {
  attendees: Array<AttendeeInput>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  meetingProvider?: InputMaybe<MeetingProvider>;
  name?: InputMaybe<Scalars['String']['input']>;
  sensitivity?: InputMaybe<CalendarEventSensitivity>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateCalendarSourceInput = {
  settings?: InputMaybe<SettingsInput>;
};

export type UpdateDeskInput = {
  floorId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  presenceDetection?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDeviceInput = {
  channelId?: InputMaybe<Scalars['ID']['input']>;
  deskId?: InputMaybe<Scalars['ID']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDeviceTypeInput = {
  attributes?: InputMaybe<DeviceTypeAttributesInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFirmwareInput = {
  channels?: InputMaybe<Array<Scalars['ID']['input']>>;
  deviceTypeId?: InputMaybe<Scalars['ID']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  variant?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFloorInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateInvitationInput = {
  extendExpiration?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Role>;
};

export type UpdateLicenseInput = {
  activationTime?: InputMaybe<Scalars['DateTime']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateMembershipInput = {
  role?: InputMaybe<Role>;
};

export type UpdateOrganizationInput = {
  bookingSettings?: InputMaybe<OrganizationBookingSettingsInput>;
  brokenEquipmentReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  calendarSettings?: InputMaybe<CalendarSettingsInput>;
  deskBookingSettings?: InputMaybe<DeskBookingSettingsInput>;
  deviceErrorReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceErrorReportWebhookUrl?: InputMaybe<Scalars['String']['input']>;
  deviceScreenTemplate?: InputMaybe<DeviceScreenTemplate>;
  domain?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  logoMediaId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openHours?: InputMaybe<WeeklyOpenHoursInput>;
  roomBookingSettings?: InputMaybe<RoomBookingSettingsInput>;
  timePresentation?: InputMaybe<TimePresentation>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  whitelist?: InputMaybe<Scalars['String']['input']>;
  whitelistEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOrganizationMessageInput = {
  canBeClosed?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  hideAt?: InputMaybe<Scalars['DateTime']['input']>;
  organizations?: InputMaybe<Array<Scalars['ID']['input']>>;
  showAt?: InputMaybe<Scalars['DateTime']['input']>;
  showToAllOrganizations?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<OrganizationMessageType>;
};

export type UpdateOverviewInput = {
  columnCount?: InputMaybe<Scalars['Int']['input']>;
  floorId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  showDateTime?: InputMaybe<Scalars['Boolean']['input']>;
  showLogo?: InputMaybe<Scalars['Boolean']['input']>;
  tiles?: InputMaybe<Array<InputMaybe<UpdateTileInput>>>;
  type?: InputMaybe<OverviewType>;
};

export type UpdatePlaceInput = {
  attributes?: InputMaybe<PlaceAttributesInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentPlaceId?: InputMaybe<Scalars['ID']['input']>;
  placeTypeId: Scalars['ID']['input'];
  settings?: InputMaybe<PlaceSettingsInput>;
};

export type UpdatePlanInput = {
  annotations?: InputMaybe<Scalars['String']['input']>;
  mediaId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateRoomBookingInput = {
  /** attendees is currently ignored when updating a booking. */
  attendees?: InputMaybe<Array<AttendeeInput>>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoomInput = {
  brokenEquipmentReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  deviceScreenTemplate?: InputMaybe<DeviceScreenTemplate>;
  email?: InputMaybe<Scalars['String']['input']>;
  floorId?: InputMaybe<Scalars['ID']['input']>;
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Array<RoomResource>>;
};

export type UpdateTileInput = {
  directionIndicator?: InputMaybe<Direction>;
  floorId?: InputMaybe<Scalars['ID']['input']>;
  hideBookingInformation?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  type: TileType;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  /** @deprecated legacy, will be removed */
  eula?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitations: Array<Invitation>;
  lastName?: Maybe<Scalars['String']['output']>;
  memberships: Array<Membership>;
  name: Scalars['String']['output'];
  superAdmin: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  verified: Scalars['Boolean']['output'];
};


export type UserInvitationsArgs = {
  filter?: InputMaybe<InvitationFilter>;
};

export type UserFilter = {
  /** Only returns users where `name` or `email` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserOrder = {
  desc?: Scalars['Boolean']['input'];
  field: UserOrderField;
};

export const UserOrderField = {
  CreatedAt: 'CREATED_AT',
  Email: 'EMAIL',
  Eula: 'EULA',
  Name: 'NAME',
  SuperAdmin: 'SUPER_ADMIN',
  UpdatedAt: 'UPDATED_AT',
  Verified: 'VERIFIED'
} as const;

export type UserOrderField = typeof UserOrderField[keyof typeof UserOrderField];
export type UsersConnection = {
  __typename?: 'UsersConnection';
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  users: Array<User>;
};

export type WeeklyOpenHours = {
  __typename?: 'WeeklyOpenHours';
  fri: OpenHours;
  mon: OpenHours;
  sat: OpenHours;
  sun: OpenHours;
  thu: OpenHours;
  tue: OpenHours;
  wed: OpenHours;
};

export type WeeklyOpenHoursInput = {
  fri?: InputMaybe<OpenHoursInput>;
  mon?: InputMaybe<OpenHoursInput>;
  sat?: InputMaybe<OpenHoursInput>;
  sun?: InputMaybe<OpenHoursInput>;
  thu?: InputMaybe<OpenHoursInput>;
  tue?: InputMaybe<OpenHoursInput>;
  wed?: InputMaybe<OpenHoursInput>;
};

export type ZoneSettings = {
  __typename?: 'ZoneSettings';
  brokenEquipmentReportEmails?: Maybe<Array<Scalars['String']['output']>>;
};

export type ZoneSettingsInput = {
  brokenEquipmentReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MembershipFieldsFragment = { __typename?: 'Membership', id: string, role: Role, userId?: string | null, orgId: string, status: MembershipStatus, organization?: { __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, domain: string } | null };

export type InvitationFieldsFragment = { __typename?: 'Invitation', id: string, status: InvitationStatus, organization?: { __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, domain: string } | null, membership: { __typename?: 'Membership', id: string, role: Role } };

export type ProfileFieldsFragment = { __typename?: 'User', name: string, email: string, verified: boolean, superAdmin: boolean, memberships: Array<{ __typename?: 'Membership', id: string, role: Role, userId?: string | null, orgId: string, status: MembershipStatus, organization?: { __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, domain: string } | null }>, invitations: Array<{ __typename?: 'Invitation', id: string, status: InvitationStatus, organization?: { __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, domain: string } | null, membership: { __typename?: 'Membership', id: string, role: Role } }> };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', profile: { __typename?: 'User', name: string, email: string, verified: boolean, superAdmin: boolean, memberships: Array<{ __typename?: 'Membership', id: string, role: Role, userId?: string | null, orgId: string, status: MembershipStatus, organization?: { __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, domain: string } | null }>, invitations: Array<{ __typename?: 'Invitation', id: string, status: InvitationStatus, organization?: { __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, domain: string } | null, membership: { __typename?: 'Membership', id: string, role: Role } }> } };

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'Organization', domain: string } };

export type JoinOrganizationMutationVariables = Exact<{
  domain: Scalars['String']['input'];
}>;


export type JoinOrganizationMutation = { __typename?: 'Mutation', joinOrganization: { __typename?: 'Membership', orgId: string, userId?: string | null } };

export type AcceptInvitationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AcceptInvitationMutation = { __typename?: 'Mutation', acceptInvitation?: { __typename?: 'Invitation', id: string } | null };

export type DeclineInvitationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeclineInvitationMutation = { __typename?: 'Mutation', declineInvitation: { __typename?: 'Invitation', id: string } };
