import { SvgIcon, SvgIconProps } from '@mui/material';

export function SuperAdminIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.087 3.618A1.798 1.798 0 0 1 5.447 3H18.62c.532 0 1.036.232 1.375.634l2.6 3.085c.55.652.54 1.594-.021 2.235L13.434 19.39a1.808 1.808 0 0 1-2.695.012L1.437 8.968a1.708 1.708 0 0 1-.018-2.262l2.668-3.089Zm1.36.54a.6.6 0 0 0-.453.207L2.326 7.453a.57.57 0 0 0 .006.754l9.302 10.435a.603.603 0 0 0 .898-.004l9.139-10.437a.57.57 0 0 0 .007-.745l-2.6-3.086a.6.6 0 0 0-.459-.211H5.447Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.751 10.57c0 1.696-1.288 3.005-2.894 3.005-.656 0-1.204-.168-1.655-.457v.337h-1.69V5.871l1.69-.95v3.114c.45-.287.997-.457 1.655-.457 1.606 0 2.894 1.323 2.894 2.993Zm-1.667.002c0-.841-.632-1.574-1.508-1.574-.498 0-.997.167-1.374.661v1.838c.377.506.876.674 1.374.674.876 0 1.508-.747 1.508-1.6Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M16.416 12.776a.803.803 0 0 1-.808.799.802.802 0 0 1-.808-.799c0-.441.36-.798.808-.798.447 0 .808.357.808.798ZM7.872 13.139h.021l.127.185.127-.185h.021v.312h-.023v-.272l-.125.18-.125-.18v.272h-.023v-.312ZM7.665 13.162h-.11v-.021H7.8v.021h-.112v.291h-.023v-.29Z"
        />
      </svg>
    </SvgIcon>
  );
}
