import { alpha, createTheme } from '@mui/material/styles';

export const colors = {
  black: '#000000',
  white: '#ffffff',
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#c9c9c9',
    400: '#878787',
    500: '#646464',
    600: '#464646',
    700: '#333333',
    800: '#222222',
    900: '#111111',
  },
  biamp: '#d22730',
  successDark: '#093615',
  successLight: '#eafef0',
  successMain: '#00e941',
  errorDark: '#2E1016',
  errorLight: '#ffedf0',
  errorMain: '#ff1744',
  warningDark: '#342A0F',
  warningLight: '#fffaea',
  warningMain: '#ffb800',
  infoDark: '#101C25',
  infoLight: '#ebf7ff',
  infoMain: '#0391ff',
} as const;

export const appBarHeight = 64;

const drawerWidth = 300;

export const biampCustomTheme = (
  overrideOptions: Parameters<typeof createTheme>[0] = {},
) =>
  createTheme(
    {
      spacing: 8,
      shape: { borderRadius: 4 },
      cssVariables: {
        colorSchemeSelector: 'class',
      },
      colorSchemes: {
        light: {
          palette: {
            secondary: {
              contrastText: colors.grey[900],
              dark: colors.white,
              light: colors.white,
              main: colors.white,
            },
            success: {
              contrastText: colors.grey[900],
              dark: colors.successLight,
              light: colors.successLight,
              main: colors.successMain,
            },
            warning: {
              contrastText: colors.grey[900],
              dark: colors.warningLight,
              light: colors.warningLight,
              main: colors.warningMain,
            },
            error: {
              contrastText: colors.white,
              dark: colors.errorLight,
              light: colors.errorLight,
              main: colors.errorMain,
            },
            info: {
              contrastText: colors.white,
              dark: colors.infoLight,
              light: colors.infoLight,
              main: colors.infoMain,
            },
            grey: {
              ...colors.grey,
            },
            common: {
              black: colors.black,
              white: colors.white,
            },
            biamp: {
              contrastText: colors.white,
              dark: colors.biamp,
              light: colors.biamp,
              main: colors.biamp,
            },
            primary: {
              contrastText: colors.white,
              dark: colors.grey[900],
              light: colors.grey[900],
              main: colors.grey[900],
            },
            text: {
              primary: colors.grey[900],
              secondary: colors.grey[400],
              disabled: alpha(colors.grey[900], 0.4),
              sidebar: colors.white,
            },
            background: {
              default: colors.grey[100],
              paper: colors.white,
            },
          },
        },
        dark: {
          palette: {
            secondary: {
              contrastText: colors.grey[900],
              dark: colors.white,
              light: colors.white,
              main: colors.white,
            },
            success: {
              contrastText: colors.grey[900],
              dark: colors.successDark,
              light: colors.successDark,
              main: colors.successMain,
            },
            warning: {
              contrastText: colors.grey[900],
              dark: colors.warningDark,
              light: colors.warningDark,
              main: colors.warningMain,
            },
            error: {
              contrastText: colors.white,
              dark: colors.errorDark,
              light: colors.errorDark,
              main: colors.errorMain,
            },
            info: {
              contrastText: colors.white,
              dark: colors.infoDark,
              light: colors.infoDark,
              main: colors.infoMain,
            },
            grey: {
              ...colors.grey,
            },
            common: {
              black: colors.black,
              white: colors.white,
            },
            biamp: {
              contrastText: colors.white,
              dark: colors.biamp,
              light: colors.biamp,
              main: colors.biamp,
            },
            primary: {
              contrastText: colors.grey[900],
              dark: colors.white,
              light: colors.white,
              main: colors.white,
            },
            text: {
              primary: colors.white,
              secondary: colors.grey[400],
              disabled: alpha(colors.white, 0.4),
              sidebar: colors.white,
            },
            background: {
              default: colors.grey[900],
              paper: colors.grey[800],
            },
          },
        },
      },
      typography: {
        fontFamily: '"Open Sans", sans-serif',
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 600,
        h0: {
          fontFamily: '"Montserrat", sans-serif',
          fontSize: '3.5rem',
          fontWeight: 500,
          letterSpacing: '-0.105rem',
          lineHeight: 1.1,
        },
        h1: {
          fontFamily: '"Montserrat", sans-serif',
          fontSize: '1.75rem',
          fontWeight: 500,
          letterSpacing: '-0.07rem',
          lineHeight: 1.2,
        },
        h2: {
          fontFamily: '"Montserrat", sans-serif',
          fontSize: '1.25rem',
          fontWeight: 600,
          letterSpacing: '-0.025rem',
          lineHeight: 1.5,
        },
        h3: {
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: 1.5,
        },
        body1: {
          fontSize: '1rem',
          fontWeight: 400,
          letterSpacing: '-0.02rem',
          lineHeight: 1.5,
        },
        body2: {
          fontSize: '0.875rem',
          fontWeight: 400,
          letterSpacing: '-0.018rem',
          lineHeight: 1.5,
        },
        caption: {
          fontSize: '0.75rem',
          fontWeight: 400,
          letterSpacing: '-0.015rem',
          lineHeight: 1.5,
        },
        subtitle1: {
          fontSize: '0.875rem',
          lineHeight: 1.5,
        },
        subtitle2: {
          fontSize: '0.75rem',
          lineHeight: 1.5,
        },
        button: {
          fontSize: '0.875rem',
          letterSpacing: '-0.018rem',
          fontWeight: 700,
          textTransform: 'none',
          lineHeight: 1.5,
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: url(/open-sans-regular.woff2) format('woff2');
        }

        @font-face {
          font-family: 'Open Sans';
          font-weight: 400;
          font-style: italic;
          font-display: swap;
          src: url(/open-sans-regular-italic.woff2) format('woff2');
        }
        
        @font-face {
          font-family: 'Open Sans';
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          src: url(/open-sans-semibold.woff2) format('woff2');
        }

        @font-face {
          font-family: 'Open Sans';
          font-weight: 600;
          font-style: italic;
          font-display: swap;
          src: url(/open-sans-semibold-italic.woff2) format('woff2');
        }

        @font-face {
          font-family: 'Montserrat';
          font-weight: 500;
          font-style: normal;
          font-display: swap;
          src: url(/Montserrat-Medium.ttf) format('truetype');
        }

        @font-face {
          font-family: 'Montserrat';
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          src: url(/Montserrat-SemiBold.ttf) format('truetype');
        }
    `,
        },
        MuiAlert: {
          styleOverrides: {
            standard: ({ theme }) => ({
              color: theme.palette.text.primary,
              border: `1px solid`,
            }),
            standardError: ({ theme }) => ({
              borderColor: theme.palette.error.main,
              backgroundColor: theme.palette.error.light,
            }),
            standardInfo: ({ theme }) => ({
              borderColor: theme.palette.info.main,
              backgroundColor: theme.palette.info.light,
            }),
            standardSuccess: ({ theme }) => ({
              borderColor: theme.palette.success.main,
              backgroundColor: theme.palette.success.light,
            }),
            standardWarning: ({ theme }) => ({
              borderColor: theme.palette.warning.main,
              backgroundColor: theme.palette.warning.light,
            }),
          },
        },
        MuiButton: {
          styleOverrides: {
            root: ({ theme }) => ({
              padding: theme.spacing('10px', 2),
            }),
          },
          defaultProps: {
            disableElevation: true,
          },
          variants: [
            {
              props: { size: 'large' },
              style: ({ theme }) => ({
                minHeight: theme.spacing(8),
              }),
            },
          ],
        },
        MuiDialog: {
          styleOverrides: {
            paper: { backgroundImage: 'none' },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: ({ theme }) => ({
              fontSize: theme.typography.h1.fontSize,
              fontWeight: theme.typography.h1.fontWeight,
              letterSpacing: theme.typography.h1.letterSpacing,
              lineHeight: theme.typography.h1.lineHeight,
              padding: theme.spacing(2, 3, 1),
              '& + .MuiDialogContent-root': {
                paddingTop: `${theme.spacing(1)} !important`,
              },
            }),
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: ({ theme }) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              padding: theme.spacing(2, 3, 1),
            }),
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: ({ theme }) => ({
              '& *:nth-last-of-type(n + 3):first-of-type': {
                marginRight: 'auto',
              },
              padding: theme.spacing(1, 3, 2),
            }),
          },
        },
        MuiDrawer: {
          styleOverrides: {
            root: { width: drawerWidth },
            paper: { width: drawerWidth, backgroundImage: 'none' },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              flex: 1,
            },
            labelPlacementStart: {
              display: 'flex',
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: ({ theme }) => ({
              '&.Mui-selected': {
                backgroundColor: theme.palette.action.selected,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                '&.Mui-focusVisible': {
                  backgroundColor: theme.palette.action.focus,
                },
              },
            }),
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: ({ theme }) => ({
              borderBottomColor: theme.palette.divider,
            }),
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: ({ theme }) => ({
              backgroundColor: theme.palette.background.paper,
            }),
          },
        },
      },
    },
    overrideOptions,
  );
